<script>
	import FormText from '$lib/components/Form-Text.svelte';
	import { fly, fade } from 'svelte/transition';
	import { cubicOut } from 'svelte/easing';
	export let heading = true;
	let email = '';
	let emailValid = false;
	let errors = false;
	let success = false;
	let sending = false;
	let submit = () => {
		sending = true;
		if (emailValid) {
			sending = false;
			errors = 'invalid email';
			return;
		}
		let result = {
			firstName: null,
			lastName: null,
			email: email,
			refferer: 'foodforfundssite' + window.location.pathname
		};
		fetch('https://rfr.ronanfuruta.com/api/v1/emailsignup', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(result)
		}).then((res) => {
			if (res.ok) {
				success = true;
			} else {
				errors = res.statusText;
				sending = false;
			}
			console.log(res);
		});
	};
	const errorTransition = { y: -5, duration: 50, easing: cubicOut, delay: 0 };
</script>

<div class="emailSignUp">
	{#if heading}
		<h2 class="secondary_heading">Stay Up to Date</h2>
		<h3 class="tertiary_heading">receive occasional updates from our team</h3>
	{/if}
	{#if success}
		<div class="succcess" transition:fade={{ duration: 200, delay: 300 }}>
			<p>Success! You're email has been added to our list.</p>
		</div>
	{:else}
		<form
			action=""
			on:submit|preventDefault={submit}
			id="contact"
			transition:fade={{ duration: 200 }}
		>
			<div class="emailInput">
				<FormText
					includeValid={false}
					formId="email"
					label="Email"
					required="true"
					type="email"
					auto="email"
					bind:invalid={emailValid}
					disabled={sending}
					bind:value={email}
				/>
			</div>

			<button type="submit" class="btn">{sending ? 'sending...' : 'submit'}</button>
		</form>
		<div class="form-error-row">
			{#if errors}
				<p class="form-error" transition:fly={errorTransition}>{errors}</p>
			{/if}
		</div>
	{/if}
</div>

<style>
	form {
		display: flex;
		align-items: end;
		margin-top: 1em;
		width: 100%;
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
	}
	.emailSignUp {
		text-align: center;
	}

	.emailInput {
		margin-right: 1em;
		width: 100%;
	}
	.btn {
		display: block;
	}
	.succcess {
		font-weight: bold;
		margin-top: 3em;
	}
	@media only screen and (max-width: 500px) {
		form {
			display: block;
		}
		.btn {
			width: 100%;
			margin-top: 1em;
		}
	}
</style>
